import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject, Subscription } from "rxjs";
import { GlobalService } from "src/app/services/global.service";
import { RouterService } from "src/app/shared/services/router.service";
import { first } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { FooterEditorComponent } from '../footer-editor/footer-editor.component';
import { HeaderEditorComponent } from '../header-editor/header-editor.component';
import { TableViewComponent } from '../../../shared/components/table-view/table-view.component';
import {PermissionService} from "../../../shared/services/permissions/permission.service";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "app-header-footer-overview",
  templateUrl: "./header-footer-overview.component.html",
  styleUrls: ["./header-footer-overview.component.scss"],
})
export class HeaderFooterOverviewComponent implements OnInit, OnDestroy {
  public columns = [
    { id: "name", name: "template-type.Name" },
    {
      id: "created_at",
      name: "template-type.Created",
      type: "date",
      format: "dd/MM/yyyy",
    },
    { id: "created_by", name: "template-type.CreatedBy" },
    {
      id: "updated_at",
      name: "template-type.Updated",
      type: "date",
      format: "dd/MM/yyyy HH:mm",
    },
    { id: "updated_by", name: "template-type.UpdatedBy" },
    { id: "status", name: "template-type.Status" },
  ];
  @ViewChild(TableViewComponent,{static:true}) tableViewComponent:TableViewComponent;
  public name = "";
  public api = "global/all";
  public editRoute = null;
  public additionalParams: any = {};
  private subscription: Subscription;

  private readonly destroyed$ = new Subject<boolean>();
  constructor(
    private routereService: RouterService,
    public globalService: GlobalService,
    private dialog: MatDialog,
    private permissionService: PermissionService,
    private translate: TranslateService
  ) {
    this.subscription = this.routereService
      .getPageTitle()
      .subscribe((event) => {
        console.log(event);
        this.name = event["pageType"];
        this.additionalParams = { code: this.name };
      });
  }

  hasPermission() {
    return this.permissionService.getPermission('docgen:update|docgen:manage');
  }

  createTemplate() {
      if(this.additionalParams.code === 'footer'){
        const dialogRef = this.dialog.open(FooterEditorComponent, {
          panelClass: 'custom-modalbox',
          backdropClass: 'cdk-overlay-transparent-backdrop',
          data: {title: 'actions.CreateFooter' }

        });
        dialogRef.afterClosed().subscribe(res => this.tableViewComponent.refreshTable());
      }else{
        const dialogRef =this.dialog.open(HeaderEditorComponent, {
          panelClass: 'custom-modalbox',
          backdropClass: 'cdk-overlay-transparent-backdrop',
          data: {title: 'actions.CreateHeader' }

        });
        dialogRef.afterClosed().subscribe(res => this.tableViewComponent.refreshTable());
      }
  }

  editTemplate(templateId: number) {
    if (this.additionalParams.code === 'footer') {
      const dialogRef = this.dialog.open(FooterEditorComponent, {
        panelClass: 'custom-modalbox',
        backdropClass: 'cdk-overlay-transparent-backdrop',
        data: { id: templateId, title: 'actions.EditFooter' }
      });

      dialogRef.afterClosed().subscribe(res => this.tableViewComponent.refreshTable());
    } else {
      const dialogRef = this.dialog.open(HeaderEditorComponent, {
        panelClass: 'custom-modalbox',
        backdropClass: 'cdk-overlay-transparent-backdrop',
        data: { id: templateId, title: 'actions.EditHeader' }
      });

      dialogRef.afterClosed().subscribe(res => this.tableViewComponent.refreshTable());
    }
  }

  ngOnInit() {}
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
