<div class="header-editor-container">
  <div class="form-title">{{data?.title | translate}}</div>

  <div class="form-content">
    <mat-form-field class="example-full-width">
      <mat-label translate>template.HeaderName</mat-label>
      <input [formControl]="name" matInput required>
    </mat-form-field>
  </div>
  <div class="row">
    <div class="col">
      <div
        [froalaEditor]="options"
        [(froalaModel)]="content"
        (froalaInit)="initFroala($event)"
      ></div>
    </div>
  </div>

  <div>
    <button class="action_cancel" (click)="dialogRef.close()" translate>confirmation.Cancel</button>
    <button *ngIf="!data?.id" class="action_ok" (click)="createHeader()" translate>confirmation.Create</button>
    <button *ngIf="data?.id" class="action_ok" (click)="editHeader()" translate>confirmation.Update</button>
  </div>
</div>
