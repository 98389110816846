import { Component, Inject, OnInit } from '@angular/core';
import { FroalaEditorDirective } from 'angular-froala-wysiwyg';
import {environment} from './../../../../environments/environment';
import { GlobalService } from 'src/app/services/global.service';
import { first } from 'rxjs/operators';
import { KeycloakService } from 'keycloak-angular';
import { ImageService } from 'src/app/services/image.service';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import FroalaEditor from 'froala-editor';

@Component({
  selector: 'app-footer-editor',
  templateUrl: './footer-editor.component.html',
  styleUrls: ['./footer-editor.component.scss']
})

export class FooterEditorComponent implements OnInit {
  froalaObject: any;
  froalaEditor: FroalaEditorDirective;
  content: any;
  name = new FormControl('');
  footerId: number;
  status: string;
  lock: boolean = false;
  constructor(
    private globalService: GlobalService,
    private keycloak: KeycloakService,
    private imageService: ImageService,
    public dialogRef: MatDialogRef<FooterEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  public options: Object = {
    htmlUntouched: true,
    charCounterCount: true,
    attribution: false,
    colorsBackground: [
      '#009DE0','#F6A800',
      '#61BD6D', '#1ABC9C', '#54ACD2', '#2C82C9', '#9365B8', '#475577', '#CCCCCC',
      '#41A85F', '#00A885', '#3D8EB9', '#2969B0', '#553982', '#28324E', '#000000',
      '#F7DA64', '#FBA026', '#EB6B56', '#E25041', '#A38F84', '#EFEFEF', '#FFFFFF',
      '#FAC51C', '#F37934', '#D14841', '#B8312F', '#7C706B', '#D1D5D8', 'REMOVE'
    ],
    colorsText: [
      '#009DE0','#F6A800',
      '#61BD6D', '#1ABC9C', '#54ACD2', '#2C82C9', '#9365B8', '#475577', '#CCCCCC',
      '#41A85F', '#00A885', '#3D8EB9', '#2969B0', '#553982', '#28324E', '#000000',
      '#F7DA64', '#FBA026', '#EB6B56', '#E25041', '#A38F84', '#EFEFEF', '#FFFFFF',
      '#FAC51C', '#F37934', '#D14841', '#B8312F', '#7C706B', '#D1D5D8', 'REMOVE'
    ],
    imageAltButtons: ['imageBack'],
    fontFamily: {
      'Onest, sans-serif': 'Onest',
      'Ubuntu, sans-serif': 'Ubuntu',
      'Arial,Helvetica,sans-serif': 'Arial',
      'Georgia,serif': 'Georgia',
      'Tahoma,Geneva,sans-serif': 'Tahoma',
      "'Times New Roman',Times,serif": 'Times New Roman',
      'Verdana,Geneva,sans-serif': 'Verdana'
    },
    quickInsertTags: ['p', 'div'],
    tableInsertButtons: ['tableBack'],
    toolbarButtons: ['bold', 'italic', 'underline', 'paragraphFormat', 'alert', 'html', 'fontFamily', 'fontSize', 'clearFormatting', 'align', 'formatOL', 'formatUL', 'insertImage', 'insertTable', 'print', 'getPDF', 'insertLink', 'textColor', 'undo', 'redo'],
    toolbarButtonsXS: ['bold', 'italic', 'underline', 'paragraphFormat', 'alert', 'html', 'fontFamily', 'fontSize', 'clearFormatting', 'align', 'formatOL', 'formatUL', 'insertImage', 'insertTable', 'print', 'getPDF', 'insertLink', 'textColor', 'undo', 'redo'],
    toolbarButtonsSM: ['bold', 'italic', 'underline', 'paragraphFormat', 'alert', 'html', 'fontFamily', 'fontSize', 'clearFormatting', 'align', 'formatOL', 'formatUL', 'insertImage', 'insertTable', 'print', 'getPDF', 'insertLink', 'textColor', 'undo', 'redo'],
    toolbarButtonsMD: ['bold', 'italic', 'underline', 'paragraphFormat', 'alert', 'html', 'fontFamily', 'fontSize', 'clearFormatting', 'align', 'formatOL', 'formatUL', 'insertImage', 'insertTable', 'print', 'getPDF', 'insertLink', 'textColor', 'undo', 'redo'],
    imageUpload: true,
    imageManagerLoadURL: `${environment.imageApiUrl}/image`,
    fontFamilySelection: true,
    fontFamilyDefaultSelection: "Arial",
    imageEditButtons: true,
    documentReady: false,
    fullFeature: true,
    insertLink: true,
    fullScreen: true,
    toolbarSticky: false,
    undo: true,
    redo: true,
    height: 150,
    saveBefore:true,
    key:environment.froalaKey,
    saveInterval:2500,
    imageDelete: true,
    imageManagerDeleteURL: `${environment.imageApiUrl}/image`,
    imageManagerDeleteMethod: "DELETE",
    imageUploadRemoteUrls: true,
    imageMaxSize: 5 * 1024 * 1024,
    imageAllowedTypes: ["jpeg", "png", "jpg"],
    events:{
      "image.beforeUpload": function (files, editor, images) {
        if (files instanceof FileList) {
          const file = files[0];
          if (file instanceof Blob) {
            const name = file.name;
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = (e) => {
              const base64data = reader.result;
              fetch(`${environment.imageApiUrl}/image`, {
                method: "POST",
                headers: {
                  ...this.opts.requestHeaders,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  data: base64data,
                  filename: name || "",
                }),
              })
                .then((res) => res.json())
                .then((data) => {
                  if (data.success) {
                    this.imageManager.show();
                  }
                })
                .catch((error) => {
                  this.image.back();
                });
            };
            return false;
          }
        } else {
          const els = document.querySelectorAll(".fr-image-progress-bar-layer");
          els.forEach((element) => {
            if (element instanceof HTMLElement) element.style.display = "none";
          });
          return false;
        }
        return true;
      },
    }
  }

  ngOnInit() {
    this.footerId = this.data && this.data.id ? this.data.id : null;

    if (this.data.id) {
      this.globalService.getFooter(this.footerId)
        .pipe(first())
        .subscribe(
          (data) => {
            this.content = data.html;
            this.name.setValue(data.name);
            this.status = data.status;
          },
          (error) => {
            this.dialogRef.close(false);
          }
        );
    }
  }

  ngOnDestroy() {
  }

  saveFooter() {
    if(!this.name.value){
      this.name.markAsTouched();
      return;
    }
    this.globalService.saveFooter(this.content,this.name.value,'draft')
    .pipe(first())
    .subscribe(
      data => {
      },
      error => {
      });


  }

  createFooter() {
    if(this.lock) return;
    this.lock = true;
    if(!this.name.value){
      this.name.markAsTouched();
      return;
    }

    this.globalService.saveFooter(this.content,this.name.value,'draft')
      .pipe(first())
      .subscribe(data => {
        this.lock = false;
        this.dialogRef.close();
      })
  }

  editFooter() {
    if (!this.name.value) {
      this.name.markAsTouched();
      return;
    }
    this.globalService.editFooter(this.footerId, this.content, this.name.value, this.status)
      .subscribe(
        data => {
          this.dialogRef.close();
        },
        error => {
          this.dialogRef.close(false);
        });
  }

  public initFroala(initControls) {
    initControls.initialize();
    this.froalaObject = initControls.getEditor();
    this.keycloak.getToken().then((token) => {
      this.froalaObject.opts.requestHeaders = {
        Authorization: `Bearer ${token}`,
        Origin_path: "/docgen",
        Currentrole: localStorage.getItem("currentRole") || "",
      };
    });
    console.log("Froala object ", FroalaEditor);
  }

  getImages() {
    this.imageService.getAll().subscribe((res) => {
      let results = res;
    });
  }
}
