<div class="header-editor-container">
  <div class="form-title">{{data?.title | translate}}</div>

  <div class="form-content">
    <mat-form-field class="example-full-width">
      <mat-label translate>template.FooterName</mat-label>
      <input [formControl]="name" matInput required>
    </mat-form-field>
  </div>
  <div class="row">
    <div class="col">
      <div
        [froalaEditor]="options"
        [(froalaModel)]="content"
        (froalaInit)="initFroala($event)"
      ></div>
    </div>
  </div>

  <div>
    <span class="action_cancel" (click)="dialogRef.close()" translate>confirmation.Cancel</span>
    <span *ngIf="!data?.id" class="action_ok" (click)="createFooter()" translate>confirmation.Create</span>
    <span *ngIf="data?.id" class="action_ok" (click)="editFooter()" translate>confirmation.Update</span>
  </div>
</div>
