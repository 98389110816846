<div class="form-padding">
    <div *ngIf="data.mode!='edit'" class="form-title" translate>actions.CreateNewType</div>
    <div *ngIf="data.mode=='edit'" class="form-title" translate>actions.EditType</div>

    <div class="form-content">
        <mat-form-field class="form-input">
            <mat-label translate>template-type.Name  <span class="marker">*</span></mat-label>

            <input matInput [(ngModel)]="name">
        </mat-form-field>

        <mat-form-field class="form-input">
            <mat-label translate>template-type.UseCase</mat-label>
            <input matInput [(ngModel)]="useCase" disabled>
        </mat-form-field>
    </div>

    <div class="form-title-2">Placeholders</div>

    <div class="form-import">
        <label for="filedialog" class="form-button" translate>actions.Import</label>
        <input id="filedialog" type="file" (change)="importJSON($event)" style="display: none">

        <mat-dialog-content>
            <app-tree-view [isEditMode]=true></app-tree-view>
        </mat-dialog-content>

    </div>

</div>

<div>
    <span class="action_cancel" (click)="onDismiss()" translate>confirmation.Cancel</span>
    <span *ngIf="data.mode!='edit'" class="action_ok" (click)="onConfirm()" translate>confirmation.Create</span>
    <span *ngIf="data.mode=='edit'" class="action_ok" (click)="onConfirm()" translate>confirmation.Update</span>
</div>
